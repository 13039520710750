$red: #e74c3c;
$duration: .4s;
$green: #91cebe;
$white: #fff;

.v {
  &-form {
    &-title {
      font-size: 24px;
      font-weight: bold;
      margin-bottom: 30px;
    }
    &-control {
      width: 100%;
      padding: 10px;
      border-radius: 4px;
      border: 1px solid #efefef;
      transition: border-color $duration;
      &--error {
        border-color: $red;
      }
    }
    &-item {
      margin-bottom: 20px;
      &:last-child {
        margin-bottom: 0;
      }
    }
    &-label {
      margin-bottom: 8px;
      font-size: 13px;
      .colored {
        color: $red;
      }
    }
    &-submit {
      text-transform: uppercase;
      &:focus {
        outline: none;
      }
    }
    &-text {
      font-size: 16px;
      margin-bottom: 30px;
      &--success {
        padding: 15px;
        border: 1px solid $green;
      }
    }
    &-link {
      color: $green;
      &:hover {
        color: darken($green, 10%);
      }
      &:active {
        opacity: .8;
      }
    }
    &-footer {
      font-size: 12px;
      transition: opacity $duration, color $duration;
    }
    &-highlighted {
      padding: 20px;
      background-color: #efefef;
    }
  }
  &-modal {
    &-close {
      width: 50px;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &-dialog {
      
    }
    &-content {
      padding: 30px;
    }
  }
  &-message {
    &--warning {
      font-size: 12px;
      margin-bottom: 20px;
      padding: 5px 10px;
      border: 1px solid;
    }
    &--red {
      color: $red;
      border-color: $red;
    }
  }
  &-btn {
    border: none;
    outline: none;
    &--red {
      color: $white;
      padding: 5px 10px;
      background-color: $red;
      transition: background-color $duration, opacity $duration;
      &:hover {
        color: $white;
        background-color: rgba($red, .8);
      }
      &:focus {
        outline: none;
      }
      &:active {
        opacity: .8;
      }
    }
    &--green {
      color: $white;
      padding: 5px 10px;
      background-color: $green;
      transition: background-color $duration, opacity $duration;
      &:hover {
        color: $white;
        background-color: rgba($red, .8);
      }
      &:focus {
        outline: none;
      }
      &:active {
        opacity: .8;
      }
    }
  }
}